import styled from "styled-components";

export const PortfolioContainer = styled.div`
  padding: 4rem 0;

  .title {
    text-align: center;
    padding-bottom: 2rem;
  }
`;
