import styled from "styled-components";

export const AboutMeContainer = styled.div`
  padding: 1rem 0;

  p {
    padding: 1rem 0;
  }

  .title {
    padding-bottom: 2rem;
  }
`;
