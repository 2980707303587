export const skillsInfo = {
  title: 'Skills',
  intro: '',
  tools: [
    'Leadership',
    'Teamwork',
    'JavaScript',
    'TypeScript',
    'NodeJS',
    'Python',
    'AWS',
    'Bash',
    'Linux',
    'GraphQL',
    'SQL',
    'ReactJS',
    'ElectronJS',
    'NextJS',
    'NestJS',
    'Terraform',
  ],
};
