import josePicture from 'assets/images/me.jpg';

export const contactInfo = {
  userPicture: josePicture,
  fName: 'Jose Mauricio',
  lName: 'Trejos Castro',
  position: 'Software Engineer',
  location: 'Heredia, Costa Rica',
  email: 'jtrejos506@gmail.com',
  github: 'https://github.com/joseTrejosFF',
  githubIcon: <i className='fab fa-github' />,
  altGithub: "Jose's Github page",
  linkedin: 'https://www.linkedin.com/in/jose-506',
  linkedinIcon: <i className='fab fa-linkedin-in'></i>,
  altLinkedin: "Jose's linkedin",
  geoLocation:
    'https://www.google.com/maps/place/Heredia+Province/@10.3703082,-84.2328307,10z/data=!3m1!4b1!4m5!3m4!1s0x8fa097f481ffc08f:0x841312e0b6ea6294!8m2!3d10.473523!4d-84.0167423',
};
